<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row" id="liveEvent">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-12">
                <div class="all-tabs">
                  <div class="mx-2 opt" @click="fetchData">
                    <i style="font-size: 15px; padding-left: 4px;" class="mdi mdi-refresh"></i> <span
                      style="color: #7182df">Refresh</span>
                  </div>
                  |
                  <div :class="activeTab == 'all' ? ' tab-options' : ''" @click.prevent="fetchData('all'); currentPage = 1"
                    class="mx-2 opt" style="color: #7182df">
                    All
                  </div>
                  |
                  <div :class="activeTab == 'used' ? ' tab-options' : ''" @click.prevent="fetchData('used'); currentPage = 1"
                    class="mx-2 opt" style="color: #7182df">
                    Used
                  </div>
                  |
                  <div :class="activeTab == 'unused' ? ' tab-options' : ''" @click.prevent="fetchData('unused'); currentPage = 1"
                    class="mx-2 opt" style="color: #7182df">
                    Unused
                  </div>
                  |
                  <svg class="svg-inline--fa fa-table mx-2" height="16px" width="16px" aria-hidden="true"
                    focusable="false" data-prefix="fa" data-icon="table" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512" data-fa-i2svg="">
                    <path fill="currentColor"
                      d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z">
                    </path>
                  </svg>
                  <Span style="cursor: pointer; color: #7182df" @click="showAddQrModal()">Add Qr Codes</Span>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table striped bordered :items="tableData.data" :fields="fields" :sort-by.sync="sortBy" :per-page="0"
                :current-page="currentPage" :sort-desc.sync="sortDesc" :filter-included-fields="filterOn"
                @filtered="onFiltered" responsive="sm" class="table-bordered table-hover" :key="key">
                <template v-slot:cell(file_name)="row">
                  <b-button class="btn btn-sm btn-primary" variant="primary"
                    @click="showImageModal(row.item.file_name)">View QR
                  </b-button>
                </template>
                <template v-slot:cell(reference_id)="row">
                  <span style="cursor: pointer; color: #7182df" @click="addMemberModal(row.item.id)"
                    class="d-flex align-items-center">
                    <template v-if="row.item.reference_id === null">
                      Assign <i style="font-size: 15px; padding-left: 4px;" class="mdi mdi-plus-thick"></i>
                    </template>
                    <template v-else>
                      {{ row.item.reference_id }}
                    </template>
                  </span>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div class="dataTables_paginate paging_simple_numbers d-flex justify-content-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="tableData.total"
                      :total-pages="tableData.total_pages" :per-page="tableData.per_page" first-number
                      last-number></b-pagination>
                  </ul>
                </div>
                <div class="form-inline navbar-search">
                  <span class="mr-2">Go to a Particular Paginated Page :</span>
                  <div class="input-group">
                    <b-form-input id="case" v-model="pageNo" placeholder="Enter Page No" class="in-width">
                    </b-form-input>
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="filterPage">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <b-modal id="bvModal" v-model="showAddMemberModal" title="MCI Verification" hide-footer centered
              @hidden="clearAddPointModel">
              <template #modal-title><i class="fa fa-user" style="font-size: 20px"></i>
                <span style="padding-left: 10px;">Add Member</span>
              </template>
              <div class="col-12 d-flex">
                <b-container class="bv-example-row">
                  <b-form-group class="col-12">
                    <multiselect @search-change="fetchMemberList" id="member" :options="memberList" :multiple="false"
                      track-by="member_id" label="fnameAndLname" placeholder="Type here to search"
                      v-model="memberTagging.member_id">
                      <span slot="noOptions"> Type here to search </span>
                    </multiselect>
                  </b-form-group>
                  <b-row class="d-flex justify-content-center">
                    <section class="mt-2">
                      <b-button class="btn btn-success" variant="success" :disabled="!memberTagging.member_id"
                        @click="addMember">
                        Submit
                      </b-button>
                    </section>
                  </b-row>
                </b-container>
              </div>
            </b-modal>
            <b-modal id="bvModal1" v-model="imageModal" hide-footer centered @hidden="clearImageModal">
              <template #modal-title><i class="mdi mdi-information-outline" style="font-size: 20px"></i>
                QR Code
              </template>
              <div class="d-flex justify-content-center">
                <viewer :images="[image]">
                  <img :src="image" width="250px" height="250px" style="object-fit: cover; margin-right: 10px"
                    class="border" />
                </viewer>
              </div>
            </b-modal>
            <b-modal id="bvModal2" v-model="addQrModal" hide-footer centered @hidden="clearAddQrModal">
              <template #modal-title><i class="mdi mdi-qrcode" style="font-size: 20px"></i>
                Add QR Codes
              </template>
              <div class="col-12 d-flex">
                <b-container class="bv-example-row">
                  <b-form-group id="input-group-1" label-for="input-1" class="col-12">
                    <label>Count <span style="color: red">*</span></label>
                    <b-form-input id="input-1" v-model="count" placeholder="Enter count" type="number"
                      :state="countValidation"></b-form-input>
                  </b-form-group>
                  <b-row class="d-flex justify-content-center">
                    <section class="mt-2">
                      <b-button class="btn btn-success" variant="success" @click="addQrCode" :disabled="!isFormValid">
                        Submit
                      </b-button>
                    </section>
                  </b-row>
                </b-container>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import liveEvent from "../../../mixins/ModuleJs/qr-code";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [MixinRequest, liveEvent],
  data() {
    return {
      title: "Qr Code",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
      addQrModal: false,
      count: null,
    }
  },
  computed: {
    // Define computed property for validation
    countValidation() {
      return this.count > 0 && this.count <= 1000 ? true : false;
    },
    // Enable/disable submit button based on form validity
    isFormValid() {
      return this.countValidation;
    }
  },
  validations: {
    form: {
      member_tagging: required
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect
  }
}
</script>