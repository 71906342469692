import _ from "lodash";
import { qrCode, members } from "../../js/path";

export default {
  data() {
    return {
      isPaginate: true,
      status: false,
      pageNo: null,
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "reference_id",
        },
        {
          key: "reference_type",
        },
        {
          key: "file_name",
          label: "Qr Code",
        },
        {
          key: "is_used",
        },
        {
          key: "url_link",
        },
      ],
      activeTab: "all",
      params: "",
      filter: null,
      filterOn: [],
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      key: 0,
      currentPage: 1,
      memberList: [],
      imageModal: false,
      addQrModal: false,
      showAddMemberModal: false,
      count: 0,
      memberTagging: {
        id: null,
        member_id: null,
      },
    };
  },
  methods: {
    fetchMemberList: _.debounce(async function (query) {
      this.$store.commit("loader/updateStatus", false);
      this.memberList = [];
      if (query != "" && query != null) {
        this.$store.commit("loader/updateStatus", true);
        const url = members.getMembers;
        const data = await this.getRequest(url + "?search=" + query);
        if (data.status) {
          this.memberList = data.response.data.map((member) => ({
            member_id: member.id,
            fnameAndLname: `${member.fname} ${member.lname} - ${member.mobile_number}`,
          }));
        }
      }
      this.$store.commit("loader/updateStatus", false);
    }, 1000),

    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    showImageModal(img) {
      this.imageModal = true;
      this.image = img;
    },

    showAddQrModal() {
      this.addQrModal = true;
    },

    clearAddQrModal() {
      this.addQrModal = false;
    },

    addMemberModal(id) {
      this.showAddMemberModal = true;
      this.memberTagging.id = id;
      this.memberTagging.member_id = null;
    },

    async fetchData(txt) {
      let url = null;
      if (txt == "used") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = qrCode.getAllQr + "?filter=" + txt;
        } else if (this.filter != null && this.filter != "") {
          url = url = qrCode.getAllQr + "?filter=" + txt;
        } else {
          url = qrCode.getAllQr + "?filter=" + txt;
        }
      } else if (txt == "unused") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = qrCode.getAllQr + "?filter=" + txt;
        } else if (this.filter != null && this.filter != "") {
          url = url = qrCode.getAllQr + "?filter=" + txt;
        } else {
          url = qrCode.getAllQr + "?filter=" + txt;
        }
      } else if (txt == "all") {
        this.activeTab = txt;
        url = qrCode.getAllQr + "?filter=" + txt;
      } else {
        url = qrCode.getAllQr + "?filter=" + this.activeTab;
      }
      url += this.params;
      this.$store.commit("loader/updateStatus", true);
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async addMember() {
      this.$store.commit("loader/updateStatus", true);
      const id = this.memberTagging?.id;
      let url = `${qrCode?.addMember}${id}`;
      let dataAppend = new FormData();
      if (this.memberTagging?.member_id?.member_id) {
        dataAppend.append(
          "member_id",
          this.memberTagging?.member_id?.member_id
        );
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.tableData.data.findIndex((e) => e.id == id);
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.showAddMemberModal = false;
          this.tableData.data[index].reference_id = data.response.reference_id;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async addQrCode() {
      this.$store.commit("loader/updateStatus", true);
      let url = qrCode.getAllQr;
      let dataAppend = new FormData();
      if (this.count) {
        dataAppend.append("count", this.count);
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.addQrModal = false;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          this.params = `&page=${value}`;
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  mounted() {
    this.fetchData(this.activeTab);
  },
};
